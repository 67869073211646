// Vendor files
var $ = window.jQuery = window.$ = require('./vendor/jquery-1.11.1.min');

// require('./vendor/isotope.pkgd.min');

var $$_ = window.$$_ = require('./shared/core'); 

// include your scripts here

// require('./shared/textarea-autosize'); 
// require('./shared/img'); 
// require('./shared/map'); 
// require('./shared/parallax'); 

// window.randomColor = 
require('./vendor/randomColor'); 

require('./modules/slides'); 
require('./modules/colours'); 

// expose your functions to the global scope for testing
var mxm = {};

// init some things
$(function($){

});
